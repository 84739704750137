import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {KeycloakService} from 'keycloak-angular';
import {User} from '../../../../core/models/users/users.model';
import {splitTopics} from '../../../../core/utils/topics.helper';
import {Topic} from '../../../../core/models/topics/topics.model';
import {clients} from '../../../../core/constants/clients.config';
import {shortenDescription} from 'src/app/core/utils/article.helper';
import {clientRoles} from 'src/app/core/constants/client-roles.config';
import {Article} from '../../../../core/models/articles/articles.model';
import {UsersService} from '../../../../core/services/users/users.service';
import {TopicsService} from '../../../../core/services/topics/topics.service';
import {ArticlesFilter, parseArticleQuery, writeArticleQuery} from '../../../../core/models/articles/articles.filter';
import {ArticlesService} from '../../../../core/services/articles/articles.service';
import {map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
export class ArticleListComponent implements OnInit {
  // user permissions
  public clientRoles = clientRoles;
  public userRoles: string[] = [];

  // data
  public articles!: Article[];
  public topics!: Topic[];
  public user!: User;
  public subjectAreas!: Topic[];

  // pagination
  public collectionSize = 0;

  // params
  public articlesFilter: ArticlesFilter = {
    sortBy: 'updatedAt',
    descending: true,
    pageSize: 10,
    selectedPage: 1,
    only: '',
    select: ['title', 'shortDescription', 'description', 'updatedAt', 'registerForm', 'registerDate'],
  };

  constructor(
    public keycloakService: KeycloakService,
    private articlesService: ArticlesService,
    private topicsService: TopicsService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  async ngOnInit() {
    this.userRoles = this.keycloakService.getUserRoles(false, clients.API);
    await this.usersService.getCurrentUser().then((user: User) => {
      this.user = user;
    });

    this.route.queryParams.pipe(
      map(params => {
        parseArticleQuery(this.articlesFilter, params);

        if (!this.userRoles.includes(clientRoles.IS_ADMIN) && this.user?.institution) {
          this.articlesFilter.onlyForInstitution = this.user.institution._id;
          this.articlesFilter.onlyForFederalState = this.user.institution.federalState;
        }

        return this.articlesFilter;
      }),
      switchMap(filter => this.articlesService.getAll(filter)),
    ).subscribe(res => {
      this.articles = res.docs;
      this.collectionSize = res.total;
    });

    this.topicsService.getAll().then((res) => {
      const splittedTopics = splitTopics(res.docs);
      this.topics = splittedTopics.topics;
      this.subjectAreas = splittedTopics.subjectAreas;
    });
  }

  public handlePageChange(): void {
    this.getArticlesData(true);
  }

  public userIsAuthorFromArticle(authorId: string | undefined): boolean {
    if (!this.user || !authorId) {
      return false;
    }
    return this.user._id === authorId;
  }

  public addArticleToFavorites(article: Article) {
    (this.user.favArticles ??= []).push(article);

    this.usersService.updateOne(this.user._id, {
      favArticles: this.user.favArticles?.map(article => article._id),
    }, ['institution', 'favArticles']).then(user => {
      this.user = user;
    });
  }

  public removeArticleFromFavorites(article: Article) {
    if (this.user.favArticles) {
      this.user.favArticles = this.user.favArticles.filter(item => item._id !== article._id);
    }

    this.usersService.updateOne(this.user._id, {
      favArticles: this.user.favArticles?.map(article => article._id),
    }, ['institution', 'favArticles']).then(user => {
      this.user = user;
    });
  }

  public isFavoriteArticle(article: Article) {
    return this.user?.favArticles?.some(favArticle => favArticle._id === article._id);
  }

  public changeSort(sort: { sortBy: string, descending: boolean }): void {
    this.articlesFilter.sortBy = sort.sortBy;
    this.articlesFilter.descending = sort.descending;
    this.getArticlesData();
  }

  public shortenDescription(description: string | undefined): string {
    return shortenDescription(description);
  }

  applyArticleFilter(filter: ArticlesFilter) {
    Object.assign(this.articlesFilter, filter);
    this.getArticlesData();
  }

  private getArticlesData(onlyPageChange?: boolean): void {
    if (!onlyPageChange) {
      this.articlesFilter.selectedPage = 1;
    }


    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: writeArticleQuery(this.articlesFilter),
    });
  }
}
