<!--All articles for topic-->
<div class="ui text container">
  <h2 class="ui header">
    <div class="header">
      {{ article?.title }}
      <!--CLICK TO ADD TO FAV-->
      @if (!isFavoriteArticle) {
        <i class="favorite icon active"
           (click)="addArticleToFavorites()"
           (keydown)="addArticleToFavorites()"
           tabindex="-1"
           suiPopup popupText="Artikel zu Lesezeichen hinzufügen!"
        ></i>
      } @else {
        <i class="yellow favorite icon active"
           (click)="removeArticleFromFavorites()"
           (keydown)="removeArticleFromFavorites()"
           tabindex="-1"
           suiPopup popupText="Artikel aus Lesezeichen entfernen!"
        ></i>
      }
    </div>
    @if (userRoles?.includes(clientRoles.IS_AUTHOR)) {
      <div class="sub header">
        Erstellt/Bearbeitet am: {{ article?.updatedAt | date }}
      </div>
    }
    @if (article?.permanentFlag) {
      <div class="sub header">
        Kein Fristablauf!
      </div>
    } @else if (article?.validDate) {
      <div class="sub header">
        Fristablauf: {{ article?.validDate | date }}
      </div>
    }
    <div class="sub header">
      {{ article?.registerForm }} bis zum: {{ article?.registerDate | date }}
    </div>
    <div class="sub header">
      Kategorien:
      @for (topic of article?.topics; track topic._id) {
        <a routerLink="/articles" [queryParams]="{topics: topic._id}">{{ topic.name }}</a>
        @if (!$last) {, }
      }
    </div>
  </h2>
  <div class="ui divider"></div>
  <div class="top attached ui segment">
    <div [innerHTML]="article?.description"></div>
    <div class="ui divider"></div>
    <div class="extra">
      <label for="linkList">Links:</label>
      <ul id="linkList">
        @for (link of article?.links; track link) {
          <li>
            <a class="link" [href]="link" target="_blank">
              {{ link }}
            </a>
          </li>
        }
      </ul>
      @if (article?.englishLinks?.length) {
        <label for="engLinkList">Links to english version:</label>
        <ul id="engLinkList">
          @for (link of article?.englishLinks; track link) {
            <li>
              <a class="link" [href]="link" target="_blank">
                <i class="us flag"></i>
                {{ link }}
              </a>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</div>
