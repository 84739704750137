import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ArticlesRoutingModule} from './articles-routing.module';
import {ArticleListComponent} from './pages/article-list/article-list.component';
import {ArticleDetailComponent} from './pages/article-detail/article-detail.component';
import {SharedComponentsModule} from '../../shared/components/shared-components.module';
import {SuiAccordionModule, SuiPaginationModule, SuiPopupModule} from '@angular-ex/semantic-ui';

@NgModule({
  declarations: [
    ArticleListComponent,
    ArticleDetailComponent,
  ],
  imports: [
    CommonModule,
    ArticlesRoutingModule,
    SharedComponentsModule,
    SuiPaginationModule,
    SuiAccordionModule,
    SuiPopupModule,
  ],
})
export class ArticlesModule {
}
