import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '../../core/auth/auth-guard';
import {clientRoles} from '../../core/constants/client-roles.config';
import {ArticleListComponent} from './pages/article-list/article-list.component';
import {ArticleDetailComponent} from './pages/article-detail/article-detail.component';

const routes: Routes = [
  {
    path: ':id',
    component: ArticleDetailComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [clientRoles.IS_USER],
    },
  },
  {
    path: '',
    pathMatch: 'full',
    component: ArticleListComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [clientRoles.IS_USER],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArticlesRoutingModule {
}
