import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {KeycloakService} from 'keycloak-angular';
import {User} from 'src/app/core/models/users/users.model';
import {clients} from '../../../../core/constants/clients.config';
import {Article} from '../../../../core/models/articles/articles.model';
import {clientRoles} from '../../../../core/constants/client-roles.config';
import {UsersService} from '../../../../core/services/users/users.service';
import {ArticlesService} from '../../../../core/services/articles/articles.service';
import {switchMap} from 'rxjs/operators';


@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.css']
})
export class ArticleDetailComponent implements OnInit {
  public clientRoles = clientRoles;
  public userRoles!: string[];

  // data
  public article?: Article;
  public user?: User;
  public isFavoriteArticle = false;

  constructor(
    private articlesService: ArticlesService,
    private usersService: UsersService,
    private keycloakService: KeycloakService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  async ngOnInit() {
    this.userRoles = this.keycloakService.getUserRoles(false, clients.API);
    this.user = await this.usersService.getCurrentUser();
    this.route.params.pipe(
      switchMap(({id}) => this.articlesService.getOne(id)),
    ).subscribe(article => {
      if (this.isAllowedToAccessArticle(article)) {
        this.article = article;
        if (this.user?.favArticles?.some(a => a._id === article._id)) {
          this.isFavoriteArticle = true;
        }
      } else {
        console.log('User is not allowed to access this article');
        this.router.navigate(['/home']);
      }
    });
  }

  private isAllowedToAccessArticle(article: Article): boolean {
    if (this.userRoles.includes(clientRoles.IS_AUTHOR)) {
      return true;
    }
    if (!article.institutionRestrictions || !article.institutionRestrictions.length) {
      return true;
    }
    if (this.user?.institution) {
      return article.institutionRestrictions.includes(this.user.institution._id);
    }
    return false;
  }
  addArticleToFavorites() {
    if (!this.user || !this.article) {
      return;
    }

    if (this.user._id) {
      (this.user.favArticles ??= []).push(this.article);
    }

    this.usersService.updateOne(this.user._id, {
      favArticles: this.user.favArticles?.map(article => article._id),
    }).then(() => {
      this.isFavoriteArticle = !this.isFavoriteArticle;
    });
  }

  removeArticleFromFavorites() {
    if (!this.user || !this.article) {
      return;
    }

    if (this.user._id && this.user.favArticles) {
      this.user.favArticles = this.user.favArticles.filter(article => article._id !== this.article!._id);
    }

    this.usersService.updateOne(this.user._id, {
      favArticles: this.user.favArticles?.map(article => article._id),
    }).then(() => {
      this.isFavoriteArticle = !this.isFavoriteArticle;
    });
  }
}
